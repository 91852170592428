import React from "react"
import Link from "../utils/link"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <Header />
    <SEO title="404: Not found" />
    <section className="content">
      <div className="container">
        <h1>404 Error</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/" className="btn">
          Back home
        </Link>
      </div>
    </section>
    <Footer />
  </>
)

export default NotFoundPage
